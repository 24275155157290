import { useContext } from 'react';
import OldLink from 'next/link';
import { LanguageContext } from 'context/Language';
import { getSlug } from 'utils';
import { ArticleTag, I18n } from 'types';

interface Props {
  className?: string;
  args?: { i18n: I18n; tags: ArticleTag[] };
  href?: string;
  isTarget?: boolean;
  onClick?: () => void;
}
const Link: React.FC<Props> = ({ className, args, href = '', isTarget, onClick, children }) => {
  const { lang } = useContext(LanguageContext);

  return (
    <OldLink href={args ? getSlug(args, lang) : href}>
      <a
        target={isTarget ? '_blank' : ''}
        rel={isTarget ? 'noopener noreferrer' : ''}
        className={className}
        onClick={onClick}
      >
        {children}
      </a>
    </OldLink>
  );
};

export default Link;
